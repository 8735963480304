import React from "react";
import LogRocket from "logrocket";
import { Switch, Route, useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css";

import * as pages from "./pages";
import { Auth } from "./contexts";
import supabase from "./api/supabase";

import ScrollToTop from "./utils/ScrollToTop";

function App(props) {
  const location = useLocation();
  const background = location?.state && location?.state?.background;
  const name = location?.state && location?.state?.name;

  const user = supabase.auth.user();
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  React.useEffect(() => {
    if (!isDev) {
      LogRocket.init("mgds5e/scaffm8");
      LogRocket.identify(user?.user_metadata?.name || "Anon");
    }
  }, []);
  const renderEditModals = () => {
    switch (name) {
      default:
        return <></>;
    }
  };

  return (
    <Auth.Provider>
      <ScrollToTop />
      <Switch location={background || location}>
        <Route exact path="/" component={pages.TagsPage.Login} />
        <Route exact path="/tag/:tagID/details" component={pages.TagsPage.TagDetails} />
      </Switch>
      {background && name ? renderEditModals() : null}
    </Auth.Provider>
  );
}

export default App;