import React, { useEffect, useState } from "react";
import { CursorClickIcon } from "@heroicons/react/solid";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { tagsApi } from "../../api";

import { TwoColumnDetails, Section } from "../../common/Details";
import { Spinner, Table } from "../../common";

export const TagDetails = () => {
  const { tagID } = useParams(0);
  const location = useLocation();
  const history = useHistory();

  const tagQuery = tagsApi.useFetchTag(tagID);

  if (tagQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!tagQuery.data) return null;

  return (
    <div className="w-full mx-auto mt-8">
      <TwoColumnDetails heading="Scaffold Register Details">
        <Section title="Company Name" content={tagQuery?.data?.companyName || ""} />
        <Section title="Company Logo" content={<img className="mx-auto h-24 w-auto" src={tagQuery?.data?.companylogoUrl} alt="Workflow" />} />
        <Section
          title="Job"
          content={`${tagQuery?.data?.job?.job_num} - ${tagQuery?.data?.job?.site}` || ""}
        />
        <Section title="Tag #" content={tagQuery?.data?.tag_no || ""} />
        <Section title="Description" content={tagQuery?.data?.description || ""} />
        <Section title="Last Inspection" content={tagQuery?.data?.last_inspection || ""} />
        <Section title="Inspection Due" content={tagQuery?.data?.inspection_due || ""} />
        <Section title="Status" content={tagQuery?.data?.status || ""} />
        <Section
          title="Handover Doc"
          content={
            tagQuery?.data?.handover_doc ? (
              <a
                className="text-blue-500 font-semibold"
                href={tagQuery?.data?.handover_doc?.includes('.pdf') ? tagQuery?.data?.handover_doc : `${tagQuery?.data?.handover_doc}.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            ) : (
              ""
            )
          }
        />
        <Section
          title="Date Created"
          content={moment(tagQuery.data.created_at).format("DD/MM/YYYY") || ""}
        />
        <Section title="Created By" content={tagQuery?.data?.uploaded_by || ""} />
        <Section title="Client site foreman" content={tagQuery?.data?.job?.handover? tagQuery?.data?.job?.handover[0]?.site_forman:''}/>
        <Section title="Client site foreman email" content={tagQuery?.data?.job?.handover? tagQuery?.data?.job?.handover[0]?.site_forman_email: ''}/>

      </TwoColumnDetails>
      <div className="w-full">
        <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">App Files</h2>
        <Table
          cols={[
            {
              Header: "File",
              accessor: "link",
              Cell: ({ value }) => (
                <a href={value} target="_blank" rel="noreferrer">
                  Link
                </a>
              ),
            },
            {
              Header: "Inspected by",
              accessor: "uploaded_by",
            },
            {
              Header: "Date of inspection",
              accessor: "created_at",
              Cell: ({ value }) => moment(value).format("DD/MM/YYYY")
            }
          ]}
          tableData={tagQuery.data.files}
          displayPagination={tagQuery.data.files}
        />
      </div>
      
    </div>
    
  );
};