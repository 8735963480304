import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchTag(tag_no) {
  const { data, error } = await supabase.from("scaffold_tags").select(`*`).eq("tag_no", tag_no);
  if (error) {
    throw new Error(error.message);
  }

  console.log(error)

  return data[0];
}

export function useFetchTag(tag_no) {
  return useQuery({
    queryKey: ["tag", tag_no],
    queryFn: () => fetchTag(tag_no),
  });
}
