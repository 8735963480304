import { useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useNotificationStore } from "../../store/notifications";
import { fetchTag } from "../../api/Tags"

import logo from "../../logo.png";
import { Spinner } from "../../common";


export { TagDetails } from "./TagDetails"

export function Login() {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      tagCode: "",
    },
    onSubmit: async ({ tagCode }) => {
      setIsLoading(true);
      addNotification({
        isSuccess: true,
        heading: "looking for the information",
        content: `looking for the information`,

      });
      // const resultTag = await fetchTag(tagCode);

      // if (resultTag === undefined) {
      const response = await fetch(`https://scaffm8-tags.herokuapp.com/api/search-tag/${tagCode}`)
        .catch(error => { console.error(error); setIsLoading(false); })
      try {
        const json = await response.json();
        if (!json.tagFoundIt) {
          addNotification({
            isSuccess: false,
            heading: "This Tag does not exist. Please make sure it is the right number",
            content: `This Tag does not exist. Please make sure it is the right number`,
          });
          setIsLoading(false);
          return
        }
      }
      catch (error) {
        console.log(error)
      }
      // }

      addNotification({
        isSuccess: true,
        heading: "Tag Found it!",
        content: `Tag Found it!`,
      });
      setIsLoading(false);
      setTimeout(() => {
        history.push(`/tag/${tagCode}/details`);
      }, 1000);
    }
  },
  );

  return (
    <>
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-24 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Welcome to scaffM8
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="Tag-Number" className="sr-only">
                  Tag Number
                </label>
                <input
                  id="tagCode"
                  name="tagCode"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Tag Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tagCode}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div>
              {isLoading && <div className="items-center ml-52"><Spinner /></div>}
              {!isLoading && <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Search
              </button>}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}